<template>
  <dashboard-body v-if="me" :id="me.designerUser.id" :deadlineShow="true"/>
</template>

<script>
import DashboardBody from '@/components/App/Dashboard/DashboardBody'
/* import gql from 'graphql-tag' */

export default {
  name: 'Dashboard',
  components: { DashboardBody },
  data() {
    return {
      me: null
    }
  },
  methods: {},
  mounted() {
    this.me = this.$store.getters.getMe
    console.warn('If this component be used, need to change condition to render (cuz we have superuser && staff without related designer acc)')
  }
}
</script>
